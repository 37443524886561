import { PERSONAL_IDENTIFICATION_REQUIREMENT_ID } from "@src/appV2/Accounts/DocumentDetails/constants";
import { useGetRequirementStatus } from "@src/appV2/Accounts/Documents";
import { type HcpRequirementStatus } from "@src/appV2/Accounts/Documents/types";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { type License, useGetLicenses } from "@src/appV2/Licenses/api/useGetLicenses";
import { isBefore, parseISO } from "date-fns";

import { useIsJobInterviewsEnabled } from "../../Placements/useIsJobInterviewsEnabled";

export const OnboardingRequirementTypes = {
  LICENSE: "LICENSE",
  ID_VERIFICATION: "ID_VERIFICATION",
} as const;

export type OnboardingRequirementType =
  (typeof OnboardingRequirementTypes)[keyof typeof OnboardingRequirementTypes];

export const OnboardingRequirementStatuses = {
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
  MISSING: "MISSING",
  REJECTED: "REJECTED",
} as const;

export type OnboardingRequirementStatus =
  (typeof OnboardingRequirementStatuses)[keyof typeof OnboardingRequirementStatuses];

export interface OnboardingRequirement {
  type: OnboardingRequirementType;
  name: string;
  stepLabel: string;
  stepAction: string;
  status: OnboardingRequirementStatus;
}

function getLicenseOnboardingStatus(licenses: License[]): OnboardingRequirementStatus {
  const nonExpiredLicenses = licenses.filter(
    (license) => !(license.expiresAt && isBefore(parseISO(license.expiresAt), new Date()))
  );

  if (nonExpiredLicenses.length === 0) {
    return OnboardingRequirementStatuses.MISSING;
  }

  const statusCount = nonExpiredLicenses.reduce(
    (statusCount, license) => ({
      ...statusCount,
      [license.status]: statusCount[license.status] + 1,
    }),
    {
      [LicenseStatuses.ACTIVE]: 0,
      [LicenseStatuses.ARCHIVED]: 0,
      [LicenseStatuses.EXPIRED]: 0,
      [LicenseStatuses.EXPIRING]: 0,
      [LicenseStatuses.PENDING]: 0,
      [LicenseStatuses.REJECTED]: 0,
    }
  );

  if (statusCount[LicenseStatuses.ACTIVE] > 0) {
    return OnboardingRequirementStatuses.COMPLETED;
  }

  if (statusCount[LicenseStatuses.REJECTED] > 0) {
    return OnboardingRequirementStatuses.REJECTED;
  }

  return OnboardingRequirementStatuses.IN_PROGRESS;
}

export function getRequirementOnboardingStatus(
  requirements: HcpRequirementStatus,
  requirementId: string
): OnboardingRequirementStatus {
  if (requirements.completed.includes(requirementId)) {
    return OnboardingRequirementStatuses.COMPLETED;
  }

  if (requirements.rejected.includes(requirementId)) {
    return OnboardingRequirementStatuses.REJECTED;
  }

  if (requirements.pending.includes(requirementId)) {
    return OnboardingRequirementStatuses.IN_PROGRESS;
  }

  return OnboardingRequirementStatuses.MISSING;
}

function getOnboardingProgress(requirements: OnboardingRequirement[]) {
  if (
    requirements.every(
      (requirement) => requirement.status === OnboardingRequirementStatuses.MISSING
    )
  ) {
    return 5;
  }

  if (
    requirements.every(
      (requirement) => requirement.status === OnboardingRequirementStatuses.COMPLETED
    )
  ) {
    return 100;
  }

  return (
    5 +
    (requirements.filter(
      (requirement) => requirement.status !== OnboardingRequirementStatuses.MISSING
    ).length /
      requirements.length) *
      90
  );
}

export function useOnboardingRequirements(workerId: string) {
  const { data: licenses, isLoading: isLicensesLoading } = useGetLicenses({ workerId });
  const { hcpRequirementStatus, isLoading: isHcpRequirementStatusLoading } =
    useGetRequirementStatus({ workerId });
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();

  const licenseStatus = getLicenseOnboardingStatus(licenses ?? []);
  const idVerificationStatus = getRequirementOnboardingStatus(
    hcpRequirementStatus,
    PERSONAL_IDENTIFICATION_REQUIREMENT_ID
  );

  const requirements: OnboardingRequirement[] = [
    {
      type: OnboardingRequirementTypes.LICENSE,
      name: "license",
      stepLabel: "Upload your license",
      stepAction: `Add your license to start ${
        isJobInterviewsEnabled ? "booking interviews" : "applying jobs"
      }`,
      status: licenseStatus,
    },
    {
      type: OnboardingRequirementTypes.ID_VERIFICATION,
      name: "ID verification",
      stepLabel: "Complete ID verification",
      stepAction: `Complete your ID verification to start ${
        isJobInterviewsEnabled ? "booking interviews" : "applying jobs"
      }`,
      status: idVerificationStatus,
    },
  ].sort((requirement1, requirement2) => {
    if (requirement1.status === OnboardingRequirementStatuses.MISSING) {
      return -1;
    }

    if (requirement2.status === OnboardingRequirementStatuses.MISSING) {
      return 1;
    }

    if (requirement1.status === OnboardingRequirementStatuses.REJECTED) {
      return -1;
    }

    return 0;
  });

  const statusCount = requirements.reduce(
    (statusCount, requirement) => ({
      ...statusCount,
      [requirement.status]: statusCount[requirement.status] + 1,
    }),
    {
      [OnboardingRequirementStatuses.MISSING]: 0,
      [OnboardingRequirementStatuses.IN_PROGRESS]: 0,
      [OnboardingRequirementStatuses.REJECTED]: 0,
      [OnboardingRequirementStatuses.COMPLETED]: 0,
    }
  );

  return {
    requirements,
    isLoading: isLicensesLoading || isHcpRequirementStatusLoading,
    isRejected: statusCount[OnboardingRequirementStatuses.REJECTED] > 0,
    isPending: statusCount[OnboardingRequirementStatuses.IN_PROGRESS] > 0,
    isCompleted: statusCount[OnboardingRequirementStatuses.COMPLETED] === requirements.length,
    numberOfCompletedSteps: statusCount[OnboardingRequirementStatuses.COMPLETED],
    onboardingProgress: getOnboardingProgress(requirements),
  };
}
